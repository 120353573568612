<template>
  <section
    id="services"
    class="overflow-hidden"
  >
    <v-row
      class="accent pb-3"
      no-gutters
    >    
      <v-col
        class="text-center pa-8"
        cols="12"
      >
        <v-row  
          justify="center"
        >
          <v-col cols="12">
            <base-heading class="info--text">
              {{ title }}
            </base-heading>
          </v-col>
          <v-col
            v-for="(service, i) in myServices"
            :key="i"
            class="text-left pa-0"
            cols="12"
            md="6"
          >
          <!-- <v-card class="ma-4" min-height="195"> -->
          <v-card class="ma-4" height="270">
            <v-row
        align="end"
        class="fill-height"
      >
        <v-col
          align-self="start"
          class="px-0 ml-4"
          cols="2"
        >
          <v-avatar
              class="elevation-2 ml-8 mt-4"
              color="accent"
              :size="avatarHeight"
              tile
            >
              <v-icon
                dark
                size="52"
               > {{service.icon}}</v-icon>
            </v-avatar>
        </v-col>
        <v-col align-self="start">
          <base-heading>
           <div class="text-justify pr-8 pl-8 mt-3" v-text="service.name" />
          </base-heading>
          <base-text> 
              <div class="text-justify pr-10 pl-8" v-text="service.blurb" />
            </base-text>
        </v-col>
            </v-row>
            
          </v-card>
            
            
          </v-col>

        </v-row>
        
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BaseHeading from './base/BaseHeading.vue'
  export default {
  components: { BaseHeading },
    name: 'Services',

      computed: {
    title(){  
      return this.$store.getters['service_cms/getTitle']
    },
    description(){
      return this.$store.getters['service_cms/getDescription']
    },

    myServices(){
        return this.$store.getters['service_cms/getMyServices']
    },
    avatarHeight(){
                let val=0
                switch (this.$vuetify.breakpoint.name)
        {
          case 'xs':
          val="64"
          break;
          case 'sm':
          val="120"
          break;
          case 'md':
          val="80"
          break;
          case 'lg':
          val="100"
          break;
          case 'xl':
          val="120"
          break;
        }
        return val
      },
  },
  created(){
    this.$store.dispatch('service_cms/loadContent')
  },
   

  }
</script>
